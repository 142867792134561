//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import Popfield from "./components/Popfield.vue";
import Popdatefield from "./components/Popdatefield.vue";
export default {
  components: {
    "pop-field": Popfield,
    "pop-datefield": Popdatefield,
  },
  data() {
    return {
      activeName: 0,
      enteryData: {
        countryName: "",
        hhrActionReasonCode: "",
        hhrActionReasonCodeMeaning: "",
        hhrAddressClass: "",
        hhrAddressClassMeaning: "",
        hhrAge: "",
        hhrBirthDate: "",
        hhrChannel: "",
        hhrChannelMeaning: "",
        hhrDeptCode: "",
        hhrDeptName: "",
        hhrEdu: "",
        hhrEduMeaning: "",
        hhrEmail: "",
        hhrEmpId: "",
        hhrEmpName: "",
        hhrEmployer: "",
        hhrFirstHireDate: "",
        hhrFrzt: "",
        hhrFrztMeaning: "",
        hhrGender: "",
        hhrGenderMeaning: "",
        hhrGzfw: "",
        hhrGzfwMeaning: "",
        hhrImgUrl: "",
        hhrImgUrlList: [
          {
            hhrAttachCode: "",
            hhrAttachUuid: "",
            hhrFileName: "",
            hhrFileUrl:
              "",
            userName: null,
            _status: null,
          },
        ],
        hhrLevel: "",
        hhrLevelMeaning: "",
        hhrMajor: "",
        hhrMobile: "",
        hhrOrganizationCode: "",
        hhrOrganizationName: "",
        hhrPosnCode: "",
        hhrPosnName: "",
        hhrRsClass: "",
        hhrRsClassMeaning: "",
        hhrSchool: "",
        hhrYgClass: "",
        hhrYgClassMeaning: "",
        hhrPosnBeginDate: "",
        _token:
          "",
      },

      outOB: {
        hhrDeptName: "",
        hhrDeptDetailDesc: "",
      },
      tempData: {
        hhrPosnCode: "",
        hhrDeptCode: "",
        hhrWfOrgPerWorkTransferAttr01: "",
        hhrWfOrgPerWorkTransferAttr03: "",
        hhrActionReasonCode: "",
        hhrJjEmpid: "",
        hhrType: "",
      },
      saveSate: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let postData = {
        pathVariableMap: {
          organizationId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          hhrEmpid: this.$memberInfo.hhrEmpid,
        },
        payload: "{}",
      };

      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });
      this.axios
        .post("/boogoo/personInfoByhhrEmpidPost", postData)
        .then((res) => {
          Toast.clear();
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              console.log('personInfoByhhrEmpidPost',resultData)
              if (resultData.failed) {
                this.saveSate = false;
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.enteryData = resultData;
              }
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    onSubmit() {
      this.dosave();
    },

    dosave() {
      //let postData = this.enteryData;

      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "save",
          entityName:
            "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerWorkTransfer",
        },
        payload: JSON.stringify([this.tempData]),
      };
      console.log(this.tempData);

      this.saveSate = true;
      this.axios
        .post("/boogooForm/excuteMethod", postData, {
          headers: {
            processDefinitionKey: "PER_WORK_TRANSFER",
            employeeNum: this.$memberInfo.hhrEmpid,
          },
        })
        .then((res) => {
          console.log(res);

          if (res && res.data) {
            this.saveSate = false;
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              if (resultData.failed) {
                this.$q.notify({
                  color: "red-5",
                  textColor: "white",
                  icon: "warning",
                  position: "center",
                  message: resultData.message,
                  timeout: 1000,
                });
              } else {
                this.$q.notify({
                  type: "positive",
                  color: "grey-8",
                  textColor: "white",
                  position: "center",
                  message: "提交成功！",
                  timeout: 1000,
                });
                this.$router.replace("/oaplatform/list");
              }
            }
          }
        })
        .catch(() => {
          this.saveSate = false;
          Toast("请求出错了");
        });
    },
    popConfirm(obj) {
      console.log(obj);
      switch (obj.key) {
        case "hhrPosnCode":
          this.outOB.hhrDeptName = obj.value.obj.hhrDeptName;
          this.tempData.hhrDeptCode = obj.value.obj.hhrDeptCode;
          this.outOB.hhrDeptDetailDesc = obj.value.obj.hhrDeptDetailDesc;
          break;
      }
      this.tempData[obj.key] = obj.value.value;
      // console.log(obj.key,this[obj.key]);
      console.log(this.enteryData);
    },
    dateConfirm(obj) {
      console.log(obj);
      this.tempData[obj.key] = obj.value;
      console.log(this.tempData);
    },
  },
  mounted() {},
};
