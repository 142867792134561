import { render, staticRenderFns } from "./Itp.vue?vue&type=template&id=ae8fdcaa&scoped=true&"
import script from "./Itp.vue?vue&type=script&lang=js&"
export * from "./Itp.vue?vue&type=script&lang=js&"
import style0 from "./Itp.vue?vue&type=style&index=0&id=ae8fdcaa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae8fdcaa",
  null
  
)

export default component.exports
import {QLayout,QPageContainer,QPage,QCard,QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QPageContainer,QPage,QCard,QCardSection})
